import { createContext } from "react";
import { ExperimentTypeName } from "pages/editing/types";
import { MultiVariant } from "./MultiVariant";
import { SimpleVariant } from "./SimpleVariant";
import { BackendVariant } from "./BackendVariant";

export const VariantsFieldsContext = createContext<{
  fieldName?: string;
  numberFieldName?: string;
  isApplicable?: boolean;
  isDisabled?: boolean;
  mode?: string;
}>({});

export const applicableFieldNames = {
  [ExperimentTypeName.SIMPLE]: "variants",
  [ExperimentTypeName.MULTI_STEP]: "nextStepVariants",
  [ExperimentTypeName.BACKEND]: "variants",
};
export const applicableComponents = {
  [ExperimentTypeName.SIMPLE]: SimpleVariant,
  [ExperimentTypeName.MULTI_STEP]: MultiVariant,
  [ExperimentTypeName.BACKEND]: BackendVariant,
};
