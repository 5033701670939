import { isFuture, isPast } from "date-fns";
import { AbTestingExperiment } from "gql";
import { loopThroughObject } from "helpers/loopThroughObject";
import { safeJsonParse } from "helpers/safeJsonParse";

export type AbTestingExperimentWithRegions = AbTestingExperiment & {
  regions: string[];
};

export interface AbTestingExperimentFilter {
  name?: string;
  gaExperimentId?: string;
  multiStep?: boolean;
  backend?: boolean;
  regions?: string[];
  viewNames?: string[];
  team?: string;
}

export const isExperimentActive = <T extends AbTestingExperiment>({
  startAt,
  expireAt,
  enabled,
}: T): boolean =>
  isPast(new Date(startAt)) &&
  (!expireAt || isFuture(new Date(expireAt))) &&
  enabled;

export const isExperimentPaused = <T extends AbTestingExperiment>({
  startAt,
  expireAt,
  enabled,
}: T): boolean =>
  isPast(new Date(startAt)) &&
  (!expireAt || isFuture(new Date(expireAt))) &&
  !enabled;

export const isExperimentDraft = <T extends AbTestingExperiment>({
  startAt,
}: T): boolean => isFuture(new Date(startAt));

export const isExperimentExpired = <T extends AbTestingExperiment>({
  expireAt,
}: T): boolean => !!expireAt && isPast(new Date(expireAt));

export const groupExperiments = <T extends AbTestingExperiment>(
  experiments: T[] = []
): {
  activeExperiments: T[];
  pausedExperiments: T[];
  draftExperiments: T[];
  expiredExperiments: T[];
} => ({
  activeExperiments: experiments.filter((experiment) =>
    isExperimentActive(experiment)
  ),
  pausedExperiments: experiments.filter((experiment) =>
    isExperimentPaused(experiment)
  ),
  draftExperiments: experiments.filter((experiment) =>
    isExperimentDraft(experiment)
  ),
  expiredExperiments: experiments.filter((experiment) =>
    isExperimentExpired(experiment)
  ),
});

export const filterExperiments = (
  experimentsWithRegions: AbTestingExperimentWithRegions[] = [],
  {
    name,
    gaExperimentId,
    multiStep,
    backend,
    regions,
    viewNames,
    team,
  }: AbTestingExperimentFilter = {}
): AbTestingExperimentWithRegions[] =>
  experimentsWithRegions.filter((experiment) => {
    const nameMatch =
      !name || experiment.name.toLowerCase().includes(name.toLowerCase());
    const gaExperimentIdMatch =
      !gaExperimentId ||
      experiment.gaExperimentId
        .toLowerCase()
        .includes(gaExperimentId.toLowerCase());
    const multiStepMatch = !multiStep || experiment.multiStep === multiStep;
    const backendMatch = !backend || experiment.backend === backend;
    const regionsMatch =
      !regions?.length ||
      regions.some((region) => experiment.regions.includes(region));
    const viewNameMatch =
      !viewNames?.length ||
      viewNames.some((viewName) => experiment.viewNames.includes(viewName));
    const teamMatch = !team || experiment.team === team;

    return (
      nameMatch &&
      gaExperimentIdMatch &&
      multiStepMatch &&
      backendMatch &&
      regionsMatch &&
      viewNameMatch &&
      teamMatch
    );
  });

export const extractConditionVars = (
  conditionStr: string,
  variables: string[]
): string[] => {
  const conditionObj = safeJsonParse(conditionStr);
  if (conditionObj instanceof Object) {
    const result = {};
    loopThroughObject(
      conditionObj,
      null,
      result,
      (key, obj, parentObj, result) => {
        if (
          key === "var" &&
          variables.includes(obj[key]) &&
          Array.isArray(parentObj)
        ) {
          result[obj[key]] = [parentObj[1]].flat(Infinity);
        }
      }
    );
    for (const variable of variables) {
      if (result.hasOwnProperty(variable)) {
        return result[variable];
      }
    }
  }
  return [];
};
