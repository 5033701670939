import React, { useContext } from "react";
import { Select } from "antd-4.21.7";
import { Controller, useFormContext } from "react-hook-form";

import { FormField } from "../../ABTestForm/FormField";
import { VariantsFieldsContext } from "../config";
import { VARIANTS_NUMBER_OPTIONS } from "./config";
import { EXPERIMENT_TYPE_LABELS } from "../../ABTestForm/config";

export type CountFieldProps = { title: string };
export const CountField: React.FC<CountFieldProps> = ({ title, ...rest }) => {
  const { control } = useFormContext();
  const { isApplicable, isDisabled, numberFieldName, mode } = useContext(
    VariantsFieldsContext
  );

  const notApplicableMessage = `Not available for ${EXPERIMENT_TYPE_LABELS[mode]} experiments`;

  return (
    <FormField
      title={title}
      field={
        <Controller
          control={control}
          name={numberFieldName}
          render={({ field }) =>
            isApplicable ? (
              <Select
                disabled={isDisabled}
                {...field}
                {...rest}
                onChange={(event) => {
                  field.onChange(event);
                  field.onBlur();
                }}
              >
                {VARIANTS_NUMBER_OPTIONS.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <div
                data-qa-selector="variants-not-available-text"
                style={{ padding: "20px 0" }}
              >
                {notApplicableMessage}
              </div>
            )
          }
        />
      }
    />
  );
};
