import { ExperimentTypeName } from "../types";

export function getExperimentType(
  backend: boolean,
  multiStep: boolean
): ExperimentTypeName {
  if (backend) {
    return ExperimentTypeName.BACKEND;
  }
  if (multiStep) {
    return ExperimentTypeName.MULTI_STEP;
  }
  return ExperimentTypeName.SIMPLE;
}
