import { useMemo, useState } from "react";
import { AbTestingExperimentFilter } from "../../utils";
import { caseInsensitiveStringComparator, unique } from "helpers/array";
import { AbTestingExperiment } from "gql";
import { AutoComplete, Col, Row, Select } from "antd-4.21.7";
import { EXPERIMENT_TYPE_LABELS } from "pages/editing/components/ABTestForm/config";
import { useAvailableTeams } from "pages/editing/hooks/useAvailableTeams";
import { ExperimentTypeName, SelectOption } from "pages/editing/types";

interface ABTestsFilterProps {
  experiments: (AbTestingExperiment & { regions: string[] })[];
  setFilter: (
    filter: (filter: AbTestingExperimentFilter) => AbTestingExperimentFilter
  ) => void;
}

const FILTER_STYLE = {
  width: "100%",
  margin: "8px 8px",
  paddingLeft: 12,
};

export const ABTestsFilter = ({
  experiments,
  setFilter,
}: ABTestsFilterProps) => {
  const [nameOptions, setNameOptions] = useState<SelectOption[]>([]);
  const [gaExpIdOptions, setGaExpIdOptions] = useState<SelectOption[]>([]);
  const { availableTeams } = useAvailableTeams();

  const regionOptions = useMemo<SelectOption[]>(() => {
    if (!experiments?.length) {
      return [];
    }
    const regions: string[] = experiments.flatMap(
      (experiment) => experiment.regions
    );
    const uniqRegions: string[] = unique(regions);
    uniqRegions.sort(caseInsensitiveStringComparator);
    return uniqRegions.map((name) => ({ value: name }));
  }, [experiments]);

  const viewNameOptions = useMemo<SelectOption[]>(() => {
    if (!experiments?.length) {
      return [];
    }
    const viewNames: string[] = experiments.flatMap(
      (experiment) => experiment.viewNames
    );
    const uniqViewNames: string[] = unique(viewNames);
    uniqViewNames.sort(caseInsensitiveStringComparator);
    return uniqViewNames.map((name) => ({ value: name }));
  }, [experiments]);

  const handleSearchByName = (value: string) => {
    if (!value) {
      setNameOptions([]);
    } else {
      const experimentNames: string[] = experiments
        .map((experiment) => experiment.name)
        .filter(
          (name) => name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      const uniqExperimentNames: string[] = unique(experimentNames);
      uniqExperimentNames.sort(caseInsensitiveStringComparator);
      setNameOptions(uniqExperimentNames.map((name) => ({ value: name })));
    }
  };

  const handleSearchByGaExperimentId = (value: string) => {
    if (!value) {
      setGaExpIdOptions([]);
    } else {
      const gaExpIds: string[] = experiments
        .map((experiment) => experiment.gaExperimentId)
        .filter(
          (name) => name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
      const uniqGaExpIds: string[] = unique(gaExpIds);
      uniqGaExpIds.sort(caseInsensitiveStringComparator);
      setGaExpIdOptions(uniqGaExpIds.map((name) => ({ value: name })));
    }
  };

  return (
    <Row style={{ margin: "0 0 10px -16px" }}>
      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <AutoComplete
          style={FILTER_STYLE}
          options={nameOptions}
          onSearch={handleSearchByName}
          onChange={(value: string) =>
            setFilter((filter) => ({
              ...filter,
              name: value,
            }))
          }
          placeholder="Name"
          allowClear
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <AutoComplete
          style={FILTER_STYLE}
          options={gaExpIdOptions}
          onSearch={handleSearchByGaExperimentId}
          onChange={(value: string) =>
            setFilter((filter) => ({
              ...filter,
              gaExperimentId: value,
            }))
          }
          placeholder="GA Experiment ID"
          allowClear
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <Select
          style={FILTER_STYLE}
          options={[
            {
              value: ExperimentTypeName.SIMPLE,
              label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.SIMPLE],
            },
            {
              value: ExperimentTypeName.MULTI_STEP,
              label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.MULTI_STEP],
            },
            {
              value: ExperimentTypeName.BACKEND,
              label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.BACKEND],
            },
          ]}
          onChange={(value: string) =>
            setFilter((filter) => ({
              ...filter,
              multiStep: value === ExperimentTypeName.MULTI_STEP,
              backend: value === ExperimentTypeName.BACKEND,
            }))
          }
          placeholder="Type"
          allowClear
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <Select
          mode="multiple"
          style={FILTER_STYLE}
          options={regionOptions}
          onChange={(value: string[]) =>
            setFilter((filter) => ({
              ...filter,
              regions: value,
            }))
          }
          placeholder="Regions"
          allowClear
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <Select
          mode="multiple"
          style={FILTER_STYLE}
          options={viewNameOptions}
          onChange={(value: string[]) =>
            setFilter((filter) => ({
              ...filter,
              viewNames: value,
            }))
          }
          placeholder="View Names"
          allowClear
        />
      </Col>
      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <Select
          style={FILTER_STYLE}
          options={availableTeams.map(({ id, name }) => ({
            label: name,
            value: id,
          }))}
          onChange={(value: string) =>
            setFilter((filter) => ({
              ...filter,
              team: value,
            }))
          }
          placeholder="Team"
          allowClear
        />
      </Col>
    </Row>
  );
};
