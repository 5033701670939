import LANG_PATHS from "@retail/urls/lib/paths";
import { ExperimentTypeName } from "pages/editing/types";

export const EXPERIMENT_TYPE_LABELS = {
  [ExperimentTypeName.SIMPLE]: "Universal",
  [ExperimentTypeName.MULTI_STEP]: "Checkout flow",
  [ExperimentTypeName.BACKEND]: "Backend",
};

export const EXPERIMENT_TYPE_OPTIONS = [
  {
    label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.SIMPLE],
    value: ExperimentTypeName.SIMPLE,
    "data-qa-selector": "simple",
  },
  {
    label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.MULTI_STEP],
    value: ExperimentTypeName.MULTI_STEP,
    "data-qa-selector": "multi-step",
  },
  {
    label: EXPERIMENT_TYPE_LABELS[ExperimentTypeName.BACKEND],
    value: ExperimentTypeName.BACKEND,
    "data-qa-selector": "backend",
  },
];

export const EXPERIMENT_TYPE_PROPS_MAPPING = {
  [ExperimentTypeName.SIMPLE]: {
    label: "View Names",
    mode: "multiple",
  },
  [ExperimentTypeName.MULTI_STEP]: {
    label: "View Name",
  },
  [ExperimentTypeName.BACKEND]: {
    label: "View Names",
    mode: "tags",
  },
};

export const VIEW_NAME_OPTIONS = Object.keys(LANG_PATHS).map((viewName) => ({
  value: viewName,
  label: viewName,
}));

const CHECKOUT_PATHS = Object.entries(LANG_PATHS).filter(([_, url]) =>
  url.includes("/checkout/")
);

export const CHECKOUT_VIEW_NAMES: string[] = CHECKOUT_PATHS.map(
  ([viewName, _]) => viewName
);

export const FINANCING_VIEW_NAMES: string[] = CHECKOUT_PATHS.filter(
  ([_, url]) => url.includes("/financing/")
).map(([viewName, _]) => viewName);

export const VIEW_VARIANT_OPTIONS = Array.from({ length: 100 }, (_, idx) => ({
  value: idx === 0 ? "" : `v${idx}`,
  label: idx === 0 ? "Original" : `v${idx}`,
}));
