import { ExperimentTypeName } from "pages/editing/types";
import { useEffect, useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { VIEW_VARIANT_OPTIONS } from "../ABTestForm/config";

import {
  applicableComponents,
  applicableFieldNames,
  VariantsFieldsContext,
} from "./config";
import { CountField } from "./CountField";

export type VariantFieldsProps = {
  name: string;
  children: React.ReactNode;
  isDisabled?: boolean;
};

export const VariantFields: React.FC<VariantFieldsProps> & {
  CountField?: typeof CountField;
} = ({ name, children, isDisabled }) => {
  const { Provider } = VariantsFieldsContext;
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
    control,
  });

  const variantsNumberFieldName = `${name}Number`;
  const variantsNumber = watch(variantsNumberFieldName);
  const experimentType = watch("experimentType");

  useEffect(() => {
    const appendVariant = (index) => {
      if (
        experimentType === ExperimentTypeName.SIMPLE ||
        experimentType === ExperimentTypeName.BACKEND
      ) {
        append(
          { viewVariant: VIEW_VARIANT_OPTIONS[index].value },
          { shouldFocus: false }
        );
      } else {
        append({ nextStep: "" }, { shouldFocus: false });
      }
    };

    const newLength = parseInt(variantsNumber || 2);
    const oldLength = fields.length;
    if (newLength !== oldLength) {
      if (newLength > oldLength) {
        for (let i = oldLength; i < newLength; i++) {
          appendVariant(i);
        }
      } else {
        for (let i = oldLength; i > newLength; i--) {
          remove(i - 1);
        }
      }

      const setDefaultWeightPercents = (index: number) => {
        const weightPercentsFieldName = `${name}.${index}.weightPercents`;
        const defaultWeightPercents = Math.floor(100 / newLength);

        setValue(weightPercentsFieldName, defaultWeightPercents, {
          shouldValidate: true,
          shouldDirty: true,
        });
      };

      for (let i = 0; i < newLength; i++) {
        setDefaultWeightPercents(i);
      }
    }
  }, [variantsNumber, append, remove, fields.length, name, setValue]);

  const isApplicable = name === applicableFieldNames[experimentType];
  const VariantComponent = applicableComponents[experimentType];

  const memoizedValue = useMemo(
    () => ({
      isDisabled,
      fieldName: name,
      mode: experimentType,
      isApplicable,
      numberFieldName: variantsNumberFieldName,
    }),
    [name, isDisabled, experimentType, isApplicable, variantsNumberFieldName]
  );

  return (
    <Provider value={memoizedValue}>
      {children && <>{children}</>}
      {isApplicable && (
        <>
          {fields.map((_, i) => (
            <VariantComponent key={i} index={i} />
          ))}
        </>
      )}
    </Provider>
  );
};

VariantFields.CountField = CountField;
