import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExperimentTypeName } from "pages/editing/types";
import { findConditionErrorMessage } from "components/ConditionBuilder";

const isValidDateString = (str) => {
  return !isNaN(Date.parse(str));
};

export const EXPERIMENT_TYPE_FIELD_NAME_MAPPING = {
  [ExperimentTypeName.SIMPLE]: "variants",
  [ExperimentTypeName.MULTI_STEP]: "nextStepVariants",
  [ExperimentTypeName.BACKEND]: "variants",
};

const makeVariantSchema = (experimentType: ExperimentTypeName) =>
  Yup.object().shape({
    weightPercents: Yup.number()
      .required('"Weight Percents" are required')
      .typeError("Must be a number")
      .integer("Must be an integer")
      .min(1, "Must be greater than or equal to 1")
      .max(99, "Must be less than or equal to 99"),

    ...(experimentType === ExperimentTypeName.SIMPLE && {
      viewVariant: Yup.string(),
    }),

    ...((experimentType === ExperimentTypeName.SIMPLE ||
      experimentType === ExperimentTypeName.BACKEND) && {
      viewParams: Yup.mixed().test(
        "viewParams",
        '"View params" must be JSON like object',
        function (viewParams) {
          if (!viewParams) {
            return true;
          }
          try {
            const result = JSON.parse(viewParams);
            return result instanceof Object && !Array.isArray(result);
          } catch (e) {
            return false;
          }
        }
      ),
    }),

    ...(experimentType === ExperimentTypeName.MULTI_STEP && {
      nextStep: Yup.string(),

      skipSteps: Yup.array().of(Yup.string()).default([]),
    }),
  });

const schema = Yup.object({
  name: Yup.string().trim().required('"Name" is required'),

  gaExperimentId: Yup.string()
    .trim()
    .when(
      "autogeneratedGaExperimentId",
      (autogeneratedGaExperimentId, schema) => {
        if (autogeneratedGaExperimentId) {
          return schema;
        }
        return schema
          .required('"GA Experiment ID"  is required')
          .matches(
            /^\S[a-zA-Z0-9-_]*$/,
            "Must match lowercase/uppercase chars, numbers and '_', '-' symbols"
          );
      }
    ),

  startAt: Yup.date()
    .required('"Start At" is required')
    .typeError("Must be a date"),

  expireAt: Yup.date()
    .nullable()
    .typeError("Must be a date")
    .when("startAt", (startAt, schema) => {
      const isStartAtValidDate = isValidDateString(startAt);
      return isStartAtValidDate
        ? schema.min(startAt, '"Expire At" must be after "Start At"')
        : schema;
    }),

  experimentType: Yup.string().required(),

  viewNames: Yup.array()
    .of(Yup.string())
    .when("experimentType", (experimentType, schema) => {
      return experimentType === ExperimentTypeName.MULTI_STEP
        ? schema.length(1, "Only one view name must be selected")
        : schema.min(1, "At least one view name must be selected");
    }),

  condition: Yup.mixed()
    .nullable()
    .test((value) => {
      const errorMessage = value && findConditionErrorMessage(value);
      return (
        !errorMessage ||
        new Yup.ValidationError(errorMessage, value, "condition")
      );
    }),

  trafficPercents: Yup.number()
    .required('"Traffic Percents" are required')
    .typeError("Must be a number")
    .integer("Must be an integer")
    .min(1, "Must be greater than or equal to 1")
    .max(100, "Must be less than or equal to 100"),

  variants: Yup.array().when("experimentType", (experimentType, schema) => {
    return experimentType === ExperimentTypeName.SIMPLE ||
      experimentType === ExperimentTypeName.BACKEND
      ? schema
          .of(makeVariantSchema(experimentType))
          .min(2, "At least two variants are required")
      : schema.default([]);
  }),

  nextStepVariants: Yup.array().when(
    "experimentType",
    (experimentType, schema) => {
      return experimentType === ExperimentTypeName.MULTI_STEP
        ? schema
            .of(makeVariantSchema(ExperimentTypeName.MULTI_STEP))
            .min(2, "At least two next step variants are required")
        : schema.default([]);
    }
  ),
}).required();

export const validationResolver = yupResolver(schema);
