import { Card } from "antd-4.21.7";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { VariantsFieldsContext } from "../config";
import { CustomInputFormController } from "controls/Input";
import { IndexRow } from "../IndexRow";

export const BackendVariant = ({ index }) => {
  const {
    formState: { errors },
    watch,
    trigger,
  } = useFormContext();

  const { fieldName, isDisabled } = useContext(VariantsFieldsContext);

  const variants = watch(fieldName);
  const { viewParams: currentViewParams } = variants[index];

  const isDuplicate =
    variants.filter(
      ({ viewParams }) => (currentViewParams || "") === (viewParams || "")
    ).length > 1;

  const triggerVariantValidation = () => {
    trigger(fieldName);
  };

  return (
    <Card
      key={index}
      style={{
        margin: "16px 0",
        ...(index % 2 === 0 ? { backgroundColor: "rgba(0, 0, 0, 0.02)" } : {}),
      }}
      data-qa-selector={`backend-variant-${index}`}
    >
      <IndexRow>{index}</IndexRow>
      <CustomInputFormController
        label="View Params"
        placeholder="JSON"
        name={`${fieldName}.${index}.viewParams`}
        type="area"
        error={errors[fieldName]?.[index]?.viewParams}
        dataQa="view-params"
        isDisabled={isDisabled}
        onChange={triggerVariantValidation}
        tooltip='For example, { "index": 1 } or { "name": "test" }'
        {...(isDuplicate
          ? {
              hasFeedback: true,
              validateStatus: "warning",
              help: '"View Params" should be unique',
            }
          : {})}
      />
      <CustomInputFormController
        name={`${fieldName}.${index}.weightPercents`}
        label="Weight Percents"
        type="number"
        min="1"
        max="99"
        error={errors[fieldName]?.[index]?.weightPercents}
        dataQa="weight-percents"
        onChange={triggerVariantValidation}
        isDisabled={isDisabled}
        required
      />
    </Card>
  );
};
